import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import { toLonLat } from 'ol/proj';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import url from './data.geojson?url'; // TODO: remove `?url`?
import pin from './pin.svg?url'; // TODO: remove `?url`?

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        url: url,
        format: new GeoJSON,
    }),
    style: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: pin,
        }),
    }),
});

vectorLayer.customPopupCallback = async function(feature) {
    const fotd_parent = document.createElement('div');
    fotd_parent.innerHTML = "Flavor of the Day: ";
    document.querySelector('#popup-content').append(fotd_parent);

    const fotd_child = document.createElement('span');
    fotd_child.innerHTML = "Loading…";

    const fotd_image = document.createElement('img')
    fotd_parent.append(fotd_child, fotd_image);

    const [long, lat] = toLonLat(feature.getGeometry().getCoordinates());
    const res = await fetch(`https://api.allorigins.win/raw?url=${encodeURIComponent(`https://www.culvers.com/api/locator/getLocations?lat=${lat}&long=${long}&limit=1&t=${Date.now()}`)}`)
    const res_data = await res.json();
    fotd_child.innerHTML = res_data.data.geofences[0].metadata.flavorOfDayName;
    fotd_image.src = `https://cdn.culvers.com/menu-item-detail/${res_data.data.geofences[0].metadata.flavorOfDaySlug}`;
    fotd_image.style.width = "min(300px, 60vh)";
}

export default vectorLayer;
