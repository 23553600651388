import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import StadiaMaps from 'ol/source/StadiaMaps.js';

import chandlerLayer from './chandler/layer.js';
import amtrakRoutesLayer from './amtrak-routes/layer.js';
import amtrakLiveLayer from './amtrak-live/layer';
import viarailLayer from './viarail/layer.js';
import arenasLayer from './nhl-arenas/layer.js';
import bikepackingLayer from './bikepacking/layer.js';
import chains from './chains/index.js';
import census_bureau from './census-bureau/index.js';
import states from './states/index.js';
import national_land from './national-land/index.js';
import cellular from './cellular.js';
import light_pollution from './light_pollution.js';
import state_land from './state-land/index.js';
import trips from './trips/index.js';
import dot_cams from './dot-cams/index.js';
import survey_markers from './survey-markers/index.js';
import tjx from './tjx/index.js';
import minnesotaAdventureTrails from './minnesota-adventure-trails/index.js';
import countyAerialImagery from './county-aerial-imagery/index.js';
import cropHistory from './crop-history/index.js';

const layerCategories = [
    { // Base maps
        name: "Base maps",
        // TODO: include more?
        // - [ ] https://mc.bbbike.org/mc/
        // - [ ] https://www.thunderforest.com/pricing/
        layers: [
            {
                name: "OpenStreetMap Carto (Raster)",
                enabled: true,
                layer: new TileLayer({
                    source: new OSM(),
                }),
            },
            {
                name: "CyclOSM (Raster)",
                layer: new TileLayer({
                    source: new OSM({
                        url: "https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png", // TODO: {s}
                    }),
                }),
            },
            {
                name: "Stamen Watercolor (Raster)",
                layer: new TileLayer({
                    source: new StadiaMaps({
                        layer: 'stamen_watercolor',
                    }),
                }),
            },
            {
                name: "Stamen Toner (Raster)",
                layer: new TileLayer({
                    source: new StadiaMaps({
                        layer: 'stamen_toner',
                    }),
                }),
            },
        ],
    },
    { // Misc
        name: "Misc",
        layers: [
            {
                name: "Chandler",
                enabled: true,
                layer: chandlerLayer,
            },
            {
                name: "Amtrak Routes",
                layer: amtrakRoutesLayer,
            },
            {
                name: "Amtrak Trains (live-ish)",
                layer: amtrakLiveLayer,
            },
            {
                name: "Via Rail Routes",
                layer: viarailLayer,
            },
            {
                name: "NHL Arenas",
                layer: arenasLayer,
            },
            {
                name: "Bikepacking.com Routes",
                layer: bikepackingLayer,
            },
        ]
    },
    minnesotaAdventureTrails,
    survey_markers,
    dot_cams,
    trips,
    chains,
    census_bureau,
    states,
    national_land,
    state_land,
    cellular,
    light_pollution,
    tjx,
    cropHistory,
];

export default layerCategories;
